import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import { Cainiao } from "../../../extends/cainiao.class.js";

export default {
    // 初始化
    async init() {
        // 如果是编辑
        if (this.tplId > 0) {
            // 获取模版信息，直接跳到第二步并且不能反回
            await apis.getCainiaoDetail({ id: this.tplId }).then(res => {
                if (tools.msg(res, this)) {
                    let e = res.data;
                    // 初始化模板数据
                    this.express = e.cainiao_templates_info.logistics_companies_info.id;
                    this.templateName = e.cainiao_templates_info.standard_template_name;
                    this.checkTpl = e.template_id;
                    this.templateDetail = e.cainiao_templates_info;
                    this.selectPrinter = e.print_name;
                    // 选择的账户
                    this.selectCainiaoAccount = e.cainiao_waybill_address_info.shop_info.id;
                    // 选择的站点
                    // this.selectCainiaoSite = e.cainiao_waybill_address_info.branch_name;
                    this.selectCainiaoSite = e.waybill_address_id;
                    // 菜鸟发件人姓名
                    this.cainiaoSender = e.cainiao_waybill_address_info.send_name;
                    this.cainiaoSendPhone = e.cainiao_waybill_address_info.send_phone;
                }

            }).catch(err => {
                tools.err(err);
            });
            const loading = this.$loading({
                lock: true,
                text: '获取模板信息中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                loading.close();
                this.setStep(2);
            }, 3000);
        } else {
            // 初始化模板数据
            this.express = '';
            this.templateName = '';
            this.checkTpl = '';
            this.selectCainiaoSite = '';
            this.templateDetail = '';
            this.selectPrinter = '';
            // 选择的账户
            this.selectCainiaoAccount = '';
            // 选择的站点
            this.selectCainiaoSite = '';
            // 菜鸟发件人姓名
            this.cainiaoSender = '';
            this.cainiaoSendPhone = '';
            this.setStep(1);
        }
        // 初始化菜鸟
        this.cainiao = new Cainiao();
        //获取快递公司列表
        await apis.expressList().then((res) => {
            tools.msg(res, this) && (this.expressList = res.data);
        }).catch((error) => {
            tools.err(error, this);
        });
        // 获取所有菜鸟模版
        await apis.getCainiaoTemplate().then((res) => {
            if (tools.msg(res, this)) {
                this.allCainiaoTpl = res.data;
            }
        }).catch((error) => {
            tools.err(error, this);
        });
        // 获取数据源
        await apis.getDynamicField({ type: 3 }).then((res) => {
            this.selectSource = ["title", "num", "price", "id"];
            this.sourceList = [];

            if (tools.msg(res, this)) {
                let o = res.data.list_info;
                for (let i in o) {
                    let obj = {
                        value: i,
                        label: o[i]
                    };
                    if (i === 'status' || i === 'total_fee' || i === 'refund_status') {
                        continue;
                    }
                    this.sourceList.push(obj);
                }
            }
        }).catch((error) => {
            tools.err(error, this);
        });

        // 获取菜鸟账户信息
        await apis.getCainiaoInfo().then((res) => {
            if (tools.msg(res, this)) {
                this.cainiaoAccountList = res.data;
                this.setCainiaoSiteList();
            }
        }).catch((error) => {
            tools.err(error, this);
        });
    },
    changeSource() {
        this.textLine = [];
        this.selectSource.forEach((item, key) => {
            let l = '';
            if (item === 'num') {
                l = '3件';
            }
            if (item === 'title') {
                l = '黑美人大西瓜';
            }
            if (item === 'price') {
                l = '￥100';
            }
            if (item === 'id') {
                l = '134345';
            }
            if (item === 'payment') {
                l = '￥300';
            }
            if (item === 'payment') {
                l = '￥300';
            }
            let obj = {
                value: item,
                label: l
            };

            this.textLine.push(obj);
        });
    },
    // 获取站点列表
    setCainiaoSiteList() {
        this.cainiaoAccountList.forEach((item, key) => {
            console.log(111);
            console.log(item);
            console.log(this.selectCainiaoAccount);
            if (item.id === this.selectCainiaoAccount) {
                this.cainiaoSiteList = item.cainiao_waybill_address_info;
            }
        })
    },
    loadCainiaoTpl() {
        // 获取对应快递公司的模版
        this.currentTplList = [];
        this.templateName = '';
        for (let i in this.allCainiaoTpl) {
            if (i === this.express) {
                this.allCainiaoTpl[i].forEach(element => {
                    this.currentTplList.push(element);
                });
            }
        }
        if (this.currentTplList.length === 0) {
            this.$alert('该快递没有菜鸟电子模版，请创建普通模版！');
        }
    },
    setTplName(item) {
        this.templateName = item.standard_template_name;
        this.templateDetail = item;
    },
    async setStep(step) {
        this.cainiaoPrinterList = this.cainiao.printerList;

        if (step === 2 && this.templateName === '') {
            this.$alert('请选择一个快递公司和模版！');
            return;
        }
        // 判断是否有菜鸟账户
        if (step === 2) {
            // console.log(this.templateDetail);
            // 根据cp_code获取菜鸟账户信息
            let flag = false;
            //菜鸟账户
            console.log(this.templateDetail);
            this.cainiaoAccountList.forEach((item, key) => {
                if (!!item.cainiao_waybill_address_info[0] && !!this.templateDetail && item.cainiao_waybill_address_info[0].cp_code === this.templateDetail.cp_code) {
                    flag = true;
                }
            });
            // if (!flag) {
            //     this.$alert('您没有该快递公司的菜鸟账户，请申请账户后再创建该模版！');
            //     return;
            // }
        }
        this.step = step;
    },
    // 菜鸟信息
    showCainiao() {
        this.cainiaoAccountFlag = true;
    },
    // 保存菜鸟账户信息
    saveCainiaoAccount() {
        if (!this.selectCainiaoSite) {
            this.$message({
                type: 'error',
                message: '必须选择一个合作站点'
            });
            return;
        }
        if (!this.cainiaoSendPhone) {
            this.$message({
                type: 'error',
                message: '请输入发货人手机号'
            });
            return;
        }
        if (!this.cainiaoSender) {
            this.$message({
                type: 'error',
                message: '发货人姓名不能为空'
            });
            return;
        }
        this.cainiaoAccountFlag = false;

    },
    // 获取自定义内容
    getCustomText() {
        let arr = [];
        this.selectSource.forEach((item, key) => {
            this.sourceList.forEach((i, k) => {
                if (item === i.value) {
                    let obj = {
                        key: item,
                        label: i.label
                    };
                    arr.push(obj);
                }
            });
        });

        return arr;
    },
    // 保存模版
    saveTpl() {
        // 
        if (!this.selectCainiaoSite) {
            this.$message({
                type: 'error',
                message: '请绑定一个电子面单账户'
            });
            this.showCainiao();
            return;
        }
        let val = JSON.stringify({
            dynamic: this.getCustomText()
        });
        this.saveData = {
            template_id: this.checkTpl,
            // 	菜鸟地址id
            waybill_address_id: this.selectCainiaoSite,
            name: this.templateName,
            width: this.templateDetail.width,
            height: this.templateDetail.height,
            value: val,
            print_name: this.selectPrinter
        };
        // 判断模版id，如果等于0就是新增，否则编辑
        if (this.tplId !== 0) {
            this.saveData.id = this.tplId;
        }

        //是否保存默认账户
        if (this.cainiaoSetDefault) {
            let caiNiaoDefaultAccount = {
                waybill_address_id: this.selectCainiaoSite,
                send_phone: this.cainiaoSendPhone,
                send_name: this.cainiaoSender,
                default: 1
            };
            apis.saveLogisticscompanieswaybill(caiNiaoDefaultAccount).then(res => {
                tools.msg(res, this);
            }).catch(err => {
                tools.err(err, this);
            });
        }

        // 保存
        apis.saveUserCainiaoTemplate(this.saveData).then((res) => {
            if (tools.msg(res, this)) {
                this.$message({ type: 'success', message: '操作成功！' });
                // this.
                this.$emit('shareExit');
                this.$emit('fleshList');
            }
        }).catch((error) => {
            tools.err(error, this);
        });

    }
}