<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <el-card class="box-card">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                    <el-button type="primary" @click="newTpl" class="buttons">新增模版</el-button>
                </el-row>
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="面单名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.name}}</template>
                    </el-table-column>
                    <el-table-column label="面单"  min-width="65" align="center">
                        
                        <template slot-scope="scope"><img :src="baseServerUrl+'/'+scope.row.cainiao_templates_info.pic" style="width:130px;" alt=""></template>
                    </el-table-column>
                    <el-table-column label="物流/快递公司"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.cainiao_templates_info.logistics_companies_info.name }}</template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <!-- <el-button type="text" size="small">
                                数据管理
                            </el-button> -->
                            <el-button type="text" size="small" @click="edit(scope.row.id)" >
                                编辑
                            </el-button>
                            <el-button type="text" size="small"  @click="remove(scope.row.id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 页码 -->
                <div class="block">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="req.page"
                            :page-size="req.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                            class="flex-right">
                    </el-pagination>
                </div>
            </el-card>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="新增菜鸟模版"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="80%">
            <createShare :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [
                
            ],
            baseServerUrl: localStorage.getItem('baseUrl'),
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            req:{
                limit:100,
                pageSize:100,
                page:1,
                keywords:''
            }
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
        newTpl() {
            this.$route.query.action = '';
            this.createShareFlag = true;
            this.currentId = 0;
        },
        onSubmit(){

        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.getUserCainiaoTemplate(this.req).then((res) => {
                this.tableData = res.data;
                this.total = res.data.length;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定删除此记录?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.delUserCainiaoTemplate({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        let action = this.$route.query.action;
        if(action==='new'){
            this.createShareFlag = true;
        }
        this.getList();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>