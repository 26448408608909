<template>
  <div >
       <!-- 选择快递 -->
       <div v-if="step===1">
            <div style="width:98%;height:580px;margin:0 auto;background:#fff;overflow:auto;">
                <!-- <div class="rows">
                    <span class="left">
                        物流搜索
                    </span>
                    <span class="right" >
                        <el-input style="width:320px;" size="small"></el-input>
                    </span>
                </div> -->
                <div class="rows">
                    <span class="left">
                        物流公司
                    </span>
                    <div class="express">
                        <div class="item" v-for="(item,key) in expressList" :key="key">
                        <el-radio   
                        border
                        size="small"
                        @change="loadCainiaoTpl"
                        v-model="express" 
                        :label="item.code">{{item.name}}</el-radio></div>
                    </div>
                </div>

                <div class="rows">
                    <span class="left">
                        面单图片
                    </span>
                    <div class="express">
                        <div class="item2" v-for="(item,key) in currentTplList" :key="key" STY>
                            <label>
                                <el-radio  
                                style="opacity:0;width:5px;height:5px;"
                                v-model="checkTpl" 
                                @change="setTplName(item)"
                                :label="item.id"></el-radio>
                                <img :src="baseServerUrl+item.pic" 
                                :alt="item.standard_template_name" class="imgs " :class="checkTpl===item.id?'active':''">
                                <p style="text-align:center;height:30px;line-height:30px;">{{item.standard_template_name}}</p>
                            </label>
                        </div>

                    </div>
                </div>
            </div> 
            <div slot="footer" class="dialog-footer" style="text-align:right;height:60px;line-height:60px;margin-top:60px;position:relative;">
                <span style="position:absolute;left:0px;">
                    物流名称
                    <span class="" >
                        <el-input style="width:320px;margin-left:7px;" v-model="templateName"></el-input>
                    </span>
                </span>
                <el-button type="primary"  @click="setStep(2);">下一步</el-button>
            </div>
        </div>
        <!-- 创建模版 -->
        <div style="width:1100px;margin:0 auto;" v-if="step === 2">
            <!-- 上下步按钮 -->
            <el-button type="primary" 
            v-if="id===0"
            style="position:absolute;bottom:5px;left:10px;"
            @click="setStep(1);">上一步</el-button>

            <el-button type="primary" 
            style="position:absolute;bottom:5px;right:30px;"
            @click="saveTpl"> 保 存 </el-button>

            <div class="">
                <div class="top">
                    <div class="tcontent">
                        <div class="item">
                            模版名称：<span style="color:#f00;"> * </span>
                        <el-input v-model="templateName" style="width:150px;" size="small"></el-input>
                        </div>
                        <div class="item">
                            打印机：<span style="color:#fff;"> * </span>
                            <el-select style="width:230px;" size="small" v-model="selectPrinter">
                                <el-option 
                                v-for="(item,key) in cainiaoPrinterList" 
                                :label="item.name"
                                :value="item.name"
                                :key="key"></el-option>
                            </el-select>
                            &nbsp;
                            <el-button type="primary" size="small" href="javascript:void(0);" @click="showCainiao">绑定电子面单账户</el-button>
                        </div>
                    </div>

                    <div class="tcontent">
                        <div class="item">
                            打印位置调整
                            左：<el-input-number v-model="leftSplit" style="width:120px;" size="small"></el-input-number>
                            右：<el-input-number v-model="rightSplit" style="width:120px;" size="small"></el-input-number>
                        </div>
                        <div class="item">
                            面单大小（mm）
                            高：<el-input-number v-model="templateDetail.height" style="width:120px;" size="small"></el-input-number>
                            宽：<el-input-number v-model="templateDetail.width"  style="width:120px;" size="small"></el-input-number>
                        </div>
                    </div>

                </div>
                <div class="content">
                    <div class="flx left" style="width:920px;background:#fff;border-right:1px solid #ddd;">
                        <div style="width:360px;background:#fff;height:580px;margin:0 auto;position:relative;">
                            <div style="color:#f00;font-size:70px;position:absolute;top:100px;text-align:center;">面单上方不可编辑</div>
                            <img :src="demo_img" alt="" style="width:100%;">
                            <div style="width:100%;height:208px;background:#fff;position:absolute;bottom:0px;border:1px solid #ccc;">
                                <p  style="height:30px;line-height:30px;">
                                    <span v-for="(item,key) in textLine" :key="key" style="display:inline-block;margin-left:10px;">
                                        {{item.label}}
                                    </span>
                                </p>
                                <p  style="height:30px;line-height:30px;">
                                    <span v-for="(item,key) in textLine" :key="key" style="display:inline-block;margin-left:10px;">
                                        {{item.label}}
                                    </span>
                                </p>
                                <p  style="height:30px;line-height:30px;">
                                    <span v-for="(item,key) in textLine" :key="key" style="display:inline-block;margin-left:10px;">
                                        {{item.label}}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="flx right" style="background:#fff;width:170px;padding-left:10px;">
                        <div>
                            <div style="height:45px;line-height:45px;">选择要打印的订单字段：</div>
                            <el-select v-model="selectSource" 
                                @change="changeSource"
                                multiple placeholder="请选择">
                                <el-option
                                v-for="item in sourceList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <el-dialog
        :modal-append-to-body="false"
            style="margin-top:3%;"
            title="绑定菜鸟账户"
            :visible.sync="cainiaoAccountFlag"
            v-if="cainiaoAccountFlag"
            :modal="false"
            width="40%">
            <el-row style="width:400px;background:#fff;min-height:120px;margin:0 auto;text-align:center;">
                <p class="cainiao_row">
                    <span>
                        <span>选择账户 ：</span> <span style="color:#f00;">* </span>
                    </span>
                    <span>
                        <el-select size="small" v-model="selectCainiaoAccount">
                            <el-option 
                            v-for="(item,key) in cainiaoAccountList" 
                            :key="key"
                            :value="item.id"
                            :label="item.name"
                            @change="setCainiaoSiteList"
                            @click.native="setCainiaoSiteList"
                            ></el-option>
                        </el-select>
                    </span>
                </p>

                <p class="cainiao_row">
                    <span>
                        <span>选择站点 ：</span> <span style="color:#f00;">* </span>
                    </span>
                    <span>
                        <el-select size="small" v-model="selectCainiaoSite">
                            <el-option
                            v-for="(item,key) in cainiaoSiteList" 
                            :key="key"
                            :value="item.id"
                            :label="item.branch_name"
                            ></el-option>
                        </el-select>
                    </span>
                </p>
                <p class="cainiao_row">
                    <span>
                        <span>寄件姓名 ：</span> <span style="color:#f00;">* </span>
                    </span>
                    <span>
                        <el-input style="width:200px;" size="small" v-model="cainiaoSender"></el-input>
                    </span>
                </p>
                <p class="cainiao_row">
                    <span>
                        <span>寄件电话 ：</span> <span style="color:#f00;">* </span>
                    </span>
                    <span>
                        <el-input style="width:200px;" size="small" v-model="cainiaoSendPhone"></el-input>
                    </span>
                </p>
                <p class="cainiao_row">
                    <span>
                        <span>设为默认 ：</span> <span style="color:#f00;">* </span>
                    </span>
                    <span>
                        <span style="display:inline-block;width:200px;text-align:left;">
                            <el-switch
                                v-model="cainiaoSetDefault">
                            </el-switch>
                        </span>
                    </span>
                </p>
            </el-row>

            <div slot="footer" class="dialog-footer" style="text-align:center;">
                <el-button @click="cainiaoAccountFlag = false">取 消</el-button>
                <el-button type="primary" @click="saveCainiaoAccount">确 定</el-button>
            </div>
        </el-dialog>

  </div>
</template>

<script>
import method from "./method.js";
import data from "./objectData.js";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return data;
    },
    destroyed(){
        this.tplId = 0;
    },
    methods:method,
    mounted(){
        this.tplId = this.id;
        this.init();
    }
}
</script>

<style scoped lang="less">
.rows{
    min-height:40px;
    .left{
        display: inline-block;width:100px;
    }
    .express{
        width:92%;background: #fff;margin-left:100px;
        display: flex;flex-wrap:wrap;
        .item{
            flex: none;min-width:70px;max-width: 150px;margin-left:5px;height:50px;
        }
        .imgs{
            width:130px;cursor: pointer;
        }
        .imgs.active{
            box-shadow: -1px 1px 5px 3px  rgba(255, 81, 0, 0.877);
        }
        .item2{
            margin-right:15px;
        }
    }
}
.top{
    height:100px;background:#fff;margin-top:-10px;border-bottom:1px solid #ddd;
    .tcontent{
    display: flex;height:38px;line-height:45px;
        .item{
            flex:none;margin-right:20px;
        }
    }
}
.content{
    display: flex;height:580px;width:1100px;
    .flx{
        flex:none;
    }
}

.cainiao_row{
    height:57px;line-height:60px;
}



</style>