export default {
    express: '',
    expressImg: '',
    demo_img: require('../../../assets/img/home/cn-two.jpg'),
    step: 1,
    // 模版id，编辑的使用使用
    tplId: 0,
    waybill_address_id: 0,
    // 快递公司列表
    expressList: [],
    // 当前的菜鸟模版列表
    currentTplList: [],
    // 选中的菜鸟模版
    checkTpl: '',
    // 全部模版
    allCainiaoTpl: {},
    // 默认的服务器地址
    baseServerUrl: localStorage.getItem('baseUrl'),
    // 模版名称
    templateName: '',
    // 当前模版详情
    templateDetail: '',
    // 菜鸟对象
    cainiao: {},
    cainiaoPrinterList: [],
    selectPrinter: '',
    // 动态数据源
    sourceList: [],
    // 选中的数据源
    selectSource: [],
    // 遍历数据
    // "title", "num", "price", "id"
    textLine: [{
        value: 'title',
        label: '黑美人大西瓜'
    }, {
        value: 'num',
        label: '3件'
    }, {
        value: 'price',
        label: '￥100'
    }, {
        value: 'id',
        label: '134345'
    }],
    // 左分割
    leftSplit: 0,
    // 右分割
    rightSplit: 0,
    saveData: {

    },
    // 绑定菜鸟账户
    cainiaoAccountFlag: false,
    // 菜鸟账户信息
    //菜鸟账户列表
    cainiaoAccountList: [],
    // 根据菜鸟账户生成的站点列表
    cainiaoSiteList: [],
    // 选择的账户
    selectCainiaoAccount: '',
    // 选择的站点
    selectCainiaoSite: '',
    // 菜鸟发件人姓名
    cainiaoSender: '',
    cainiaoSendPhone: '',
    // 设为默认
    cainiaoSetDefault: true,

}